import { createSlice } from '@reduxjs/toolkit';
import storage from 'services/utils/storage';

export const generalReducer = createSlice({
  name: 'general',
  initialState: {
    language: storage.getItem('language') || 'en-US',
    host: null,
    companyConfig: {
      isAppointmentScheduler: false,
      operatorsEnabled: false,
      locationEnabled: false,
      addressEnabled: false,
      addChatSourceToLead: false,
      docusignEnabled: false,
      voiceEnabled: false,
      voiceForAny: false
    },
    alertState: {
      show: false,
      type: 'success',
      heading: '',
      content: '',
      saveBtn: false,
      closeBtn: true,
      saveBtnText: '',
      closeBtnText: '',
      loading: false,
      onSave: null,
      onClose: null
    }
  },
  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setLanguage: (state, action) => {
      state.language = action.payload || 'en-US';
      storage.setItem('language', state.language);
    },
    /**
     * @name @setGeneralState
     * @description to save general info in general state
     * @requires state,action for payload, Note: action.payload must be a object
     **/
    setGeneralState: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        state[key] = action.payload[key];
      });
    },
    setCompanyConfig: (state, action) => {
      state.companyConfig = { ...state.companyConfig, ...action.payload };
    },
    showAlert: (state, action) => {
      state.alertState = {
        ...state.alertState,
        ...action.payload,
        show: true,
        onClose: action.payload?.onClose || state.alertState.onClose
      };
    },
    hideAlert: state => {
      state.alertState.show = false;
    },
    setSwalLoading: (state, action) => {
      state.alertState.loading = action.payload;
    }
  }
});
export const {
  setLanguage,
  setGeneralState,
  setCompanyConfig,
  showAlert,
  hideAlert,
  setSwalLoading
} = generalReducer.actions;
export const stopPropogation = ref => dispatch => {
  /**
   * hide on clicked outside of the element
   */
  return new Promise((resolve, reject) => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        return resolve(true);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

export const selectShow = state => state.view;

export const selectLangauge = state => {
  return state.general.language;
};

export const getGeneralInfo = state => {
  return state.general;
};

export const getCompanyConfig = state => {
  return state.general.companyConfig;
};

export const getSpecificGeneralInfo = key => state => {
  return state.general[key];
};

export const getAlertState = state => {
  return state.general.alertState;
};

export default generalReducer.reducer;
